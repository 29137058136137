<template>
  <div id="page-nilai-rujukan-lab">
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>Edit Data Nilai Rujukan Lab</strong>
              </h5>
            </template>
            <b-row>
              <b-col cols="12" md="12" lg="12" xl="12">
                <b-form>
                  <b-form-group label-cols-md="3">
                    <template v-slot:label>
                      Parameter Pemeriksaan <span class="text-danger">*</span>
                    </template>
                    <multiselect
                      :options="list_sub_tindakan"
                      :state="checkIfValid('sub_tindakan_lab')"
                      v-model="$v.is_data.sub_tindakan_lab.$model"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      label="nama_sub_tindakan_lab"
                      track-by="sub_tindakan_lab_id"
                      placeholder="-- Pilih Parameter --"
                      size="sm"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group label-cols-md="3">
                    <template v-slot:label>
                      Jenis Kelamin <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      :options="jenis_kelamin"
                      :state="checkIfValid('jenis_kelamin_lab')"
                      v-model="$v.is_data.jenis_kelamin_lab.$model"
                      placeholder="-- Pilih Jenis Kelamin --"
                    ></b-form-select>
                  </b-form-group>

                  <b-form-group label-cols-md="3">
                    <template v-slot:label>
                      Dari Umur <span class="text-danger">*</span>
                    </template>
                    <div style="width: 100%;display: flex;">
                      <div style="width:33.33%;display: flex;">
                        <div style="width:25%;height: 35px;background-color: #b9bec7;color:#4f5d73;border-top-left-radius: 0.25rem;border-bottom-left-radius: 0.25rem;display: flex;justify-content: center;align-items: center;">
                          <h6 style="margin:0;font-weight: normal;font-size: 12px;">Tahun</h6>
                        </div>
                        <b-form-input
                          v-model="is_data.dari_umur_tahun"
                          type="number"
                        ></b-form-input>
                      </div>

                      <div style="width:33.33%;display: flex;margin:0 10px">
                        <div style="width:25%;height: 35px;background-color: #b9bec7;color:#4f5d73;border-top-left-radius: 0.25rem;border-bottom-left-radius: 0.25rem;display: flex;justify-content: center;align-items: center;">
                          <h6 style="margin:0;font-weight: normal;font-size: 12px;">Bulan</h6>
                        </div>
                        <b-form-input
                          v-model="is_data.dari_umur_bulan"
                          type="number"
                        ></b-form-input>
                      </div>

                      <div style="width:33.33%;display: flex;">
                        <div style="width:25%;height: 35px;background-color: #b9bec7;color:#4f5d73;border-top-left-radius: 0.25rem;border-bottom-left-radius: 0.25rem;display: flex;justify-content: center;align-items: center;">
                          <h6 style="margin:0;font-weight: normal;font-size: 12px;">Hari</h6>
                        </div>
                        <b-form-input
                          v-model="is_data.dari_umur_hari"
                          type="number"
                        ></b-form-input>
                      </div>
                    </div>
                  </b-form-group>

                  <b-form-group label-cols-md="3">
                    <template v-slot:label>
                      Sampai Umur <span class="text-danger">*</span>
                    </template>
                    <div style="width: 100%;display: flex;">
                      <div style="width:33.33%;display: flex;">
                        <div style="width:25%;height: 35px;background-color: #b9bec7;color:#4f5d73;border-top-left-radius: 0.25rem;border-bottom-left-radius: 0.25rem;display: flex;justify-content: center;align-items: center;">
                          <h6 style="margin:0;font-weight: normal;font-size: 12px;">Tahun</h6>
                        </div>
                        <b-form-input
                          v-model="is_data.sampai_umur_tahun"
                          type="number"
                        ></b-form-input>
                      </div>

                      <div style="width:33.33%;display: flex;margin:0 10px">
                        <div style="width:25%;height: 35px;background-color: #b9bec7;color:#4f5d73;border-top-left-radius: 0.25rem;border-bottom-left-radius: 0.25rem;display: flex;justify-content: center;align-items: center;">
                          <h6 style="margin:0;font-weight: normal;font-size: 12px;">Bulan</h6>
                        </div>
                        <b-form-input
                          v-model="is_data.sampai_umur_bulan"
                          type="number"
                        ></b-form-input>
                      </div>

                      <div style="width:33.33%;display: flex;">
                        <div style="width:25%;height: 35px;background-color: #b9bec7;color:#4f5d73;border-top-left-radius: 0.25rem;border-bottom-left-radius: 0.25rem;display: flex;justify-content: center;align-items: center;">
                          <h6 style="margin:0;font-weight: normal;font-size: 12px;">Hari</h6>
                        </div>
                        <b-form-input
                          v-model="is_data.sampai_umur_hari"
                          type="number"
                        ></b-form-input>
                      </div>
                    </div>
                  </b-form-group>

                  <b-form-group label-cols-md="3">
                    <template v-slot:label>
                      Nilai Minimum <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      :state="checkIfValid('nilai_minimum')"
                      v-model="$v.is_data.nilai_minimum.$model"
                      type="number"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-md="3">
                    <template v-slot:label>
                      Nilai Maximum <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      :state="checkIfValid('nilai_maximum')"
                      v-model="$v.is_data.nilai_maximum.$model"
                      type="number"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-md="3">
                    <template v-slot:label>
                      Nilai Normal Text <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      :state="checkIfValid('nilai_normal_text')"
                      v-model="$v.is_data.nilai_normal_text.$model"
                      type="text"
                      @input="reset()"
                    ></b-form-input>
                  </b-form-group>

                  <!-- <b-form-group label-cols-md="3">
                    <template v-slot:label>
                      Hasil <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      :state="checkIfValid('hasil_lab')"
                      v-model="$v.is_data.hasil_lab.$model"
                      type="number"
                    ></b-form-input>
                  </b-form-group> -->

                  <b-form-group label-cols-md="3">
                    <template v-slot:label>
                      Nilai Normal <span class="text-danger">*</span>
                    </template>
                    <b-form-radio-group
                      :options="options"
                      v-model="is_data.nilai_lab"
                      class="mb-3"
                      value-field="item"
                      text-field="name"
                      disabled-field="notEnabled"
                      @change="setNilai(is_data.nilai_lab)"
                      style="margin-bottom: 0 !important;"
                    ></b-form-radio-group>
                  </b-form-group>

                  <b-form-group label-cols-md="3">
                    <template v-slot:label>
                      Keterangan<span class="text-danger">*</span>
                    </template>
                    <b-form-textarea
                      :state="checkIfValid('keterangan_nilai_lab')"
                      v-model="$v.is_data.keterangan_nilai_lab.$model"
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-form-group>
                </b-form>
              </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" offset-md="10" offset-sm="10" offset-lg="10" class="mt-3">
                  <b-button :disabled="!isValid" variant="success" @click="simpanData()">Simpan Data</b-button>
                </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "edit_nilai_rujukan",
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      list_sub_tindakan: [

      ],

      jenis_kelamin: [
        {value: 'male', text: 'Male'},
        {value: 'female', text: 'Female'},
        {value: 'all', text: 'All'},
      ],

      options: [
        { item: 'normal', name: 'Normal' },
        { item: 'negatif', name: 'Negatif' },
        { item: 'positif', name: 'Positif' },
        { item: 'reactive', name: 'Reactive' },
        { item: 'non_reactive', name: 'Non Reactive' },
      ],
      is_data: {
        dari_umur_bulan: 0,
        dari_umur_hari: 0,
        dari_umur_tahun: 0,
        sampai_umur_bulan: 0,
        sampai_umur_hari: 0,
        sampai_umur_tahun: 0,
        jenis_kelamin_lab: null,
        nilai_minimum: null,
        nilai_maximum: null,
        nilai_normal_text: null,
        // hasil_lab: null,
        nilai_lab: null,
        keterangan_nilai_lab: null,
        sub_tindakan_lab_id: null,
        sub_tindakan_lab: null,
      },
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    id_nilai() {
      return this.$route.query ? this.$route.query.id : null;
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      // dari_umur_tahun: { required },
      // sampai_umur_tahun: { required },
      jenis_kelamin_lab: { required },
      nilai_maximum: { required },
      nilai_minimum: { required },
      nilai_normal_text: { required },
      // nilai_lab: { required },
      keterangan_nilai_lab: { required },
      sub_tindakan_lab: { required },
      // hasil_lab: { required },
    },
  },
  activated(){
      this.getData();
    },
  async mounted() {
    // Set the initial number of items
    this.getData()
    
  },
  // watch: {
  //   'is_data': {
  //     handler(data) {
  //       const vm = this
  //       vm.is_data.sub_tindakan_lab = vm.$findKey(vm.list_sub_tindakan, data, true, 'sub_tindakan_lab_id')
  //       console.log(data, 'afguawgckdahsikcgfbilasbgcfilasildcasli');
  //     },
  //      deep: true
  //   },
  // },
  methods: {
    reset(){
      let vm = this
      vm.is_data.nilai_lab = null
    },
    setNilai(x){
      let vm = this
      vm.is_data.nilai_normal_text = x
    },
    async getData(){
      let vm = this
      let res2 = await vm.$axios.post("/sub_tindakan_lab/list");
        // console.log(res2, 'ini ressnya');
      vm.list_sub_tindakan = res2.data.data
      this.setData()
    },
    setData(){
      let vm = this
      vm.$axios('/ms_nilai_lab/details_by_id/' + vm.id_nilai)
      .then((res) => {
          // console.log(res, 'ini ress');
          vm.is_data = res.data.data[0]
          // console.log(vm.is_data, 'ini sub tindakan');
          for (let i = 0; i < vm.list_sub_tindakan.length; i++) {
            let x = vm.list_sub_tindakan[i];
            if(vm.is_data.sub_tindakan_lab_id == x.sub_tindakan_lab_id){
              vm.is_data.sub_tindakan_lab = x
            }
          }
          
      })
      .catch((err) => {
          console.log(err);
      })
      // console.log(vm.data_edit, 'ini data edit');
    },
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpanData(){
      let vm = this
      vm.is_data.sub_tindakan_lab_id = vm.is_data.sub_tindakan_lab.sub_tindakan_lab_id
      vm.is_data.dari_umur_bulan = parseInt(vm.is_data.dari_umur_bulan)
      vm.is_data.dari_umur_tahun = parseInt(vm.is_data.dari_umur_tahun)
      vm.is_data.dari_umur_hari = parseInt(vm.is_data.dari_umur_hari)
      vm.is_data.sampai_umur_bulan = parseInt(vm.is_data.sampai_umur_bulan)
      vm.is_data.sampai_umur_tahun = parseInt(vm.is_data.sampai_umur_tahun)
      vm.is_data.sampai_umur_hari = parseInt(vm.is_data.sampai_umur_hari)
      // vm.is_data.nilai_maximum = parseInt(vm.is_data.nilai_maximum)
      // vm.is_data.nilai_minimum = parseInt(vm.is_data.nilai_minimum)
      vm.is_data.id = vm.is_data.ms_nilai_lab_id
      // console.log(vm.is_data, 'ini');
      vm.$axios.post("/ms_nilai_lab/update", vm.is_data)
      .then((res) => {
        // console.log(res, 'ini ress');
        if(res.data.status == 200 && res.data.message == 'sukses'){
            vm.$store.commit('set_alert', {msg: "SUKSES MENGUBAH NILAI LABORATORIUM", variant: "success", showing: true})
            vm.$router.push({
                path: "/laboratorium/nilai_rujukan_lab",
                // query: { id_ba : data.item.id },
            });
          }else{
            vm.$store.commit('set_alert', {msg: res.data.message, variant: "danger", showing: true})
          }
      })
      .catch((err) => {
        console.log(err);
        vm.$store.commit('set_alert', {msg: "TERJADI KESALAHAN PADA SERVER", variant: "danger", showing: true})
      })
    },
  },
};
</script>

<style scoped>
.input-group-text{
  font-size: 12px;
}


</style>
